<template>
  <v-app class="login">
    <span v-if="isMultiUser" class="logout-label" @click="$emit('logout')">sair</span>
    <div class="background-banner" :style="{ 'background-image': `url('${background}')` }" />
    <slot />
  </v-app>
</template>

<script>
import { mapState } from 'pinia'

import { useStore as mainUseStore } from '@/store'

export default {
  name: 'PageTemplate',

  props: {
    isMultiUser: {
      type: Boolean,
      default: () => false,
    },
  },

  computed: {
    ...mapState(mainUseStore, {
      background: 'getCurrentBackground',
    }),
  },
}
</script>

<style lang="scss">
.login {
  display: contents;

  .background-banner {
    background-position: center center;
    background-clip: border-box;
    background-repeat: no-repeat;
    background-size: cover;
    height: 332px;
    width: 100vw;
    position: absolute;
  }

  & .title {
    font-weight: 400 !important;
    font-size: 24px !important;
    line-height: 56px !important;
  }

  .logout-label {
    z-index: 50;
    text-align: end;
    color: #fff;
    text-transform: capitalize;
    margin: 15px 20px 0 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    width: fit-content;
    display: flex;
    align-self: self-end;
  }
}
</style>
